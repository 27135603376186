import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/particular.css";
import { Link } from "react-router-dom";

function Particular() {
  return (
    <div className="main-container">
      <Header />
      <div className="contactAll">
        <div className="contactLeftBottomTitle">
          <h1 className="privacy_title_h1">サイトに関して</h1>
          <h2 className="privacy_title_h2">特定商取引法に基づく表示</h2>
          <hr />
          <dl className="tradelaw">
            <dt style={{ marginTop: "14px" }}>販売業者の名称</dt>
            <dd>有限会社ゴシップ</dd>

            <dt>販売業者の住所</dt>
            <dd>
              〒923-0861
              <br />
              石川県小松市沖町ロ28-1
            </dd>

            <dt>運営統括責任者</dt>
            <dd>宮村 仁規</dd>

            <dt>適格請求書発行事業者登録番号</dt>
            <dd>T3220002013520</dd>

            <dt>商品の価格</dt>
            <dd>各商品ごとに表示</dd>

            <dt>商品代金以外の必要な金額</dt>
            <dd>
              配送料：<Link to="/">詳細はこちら</Link>
              <br />
              代金引換手数料：1万円未満 330円、3万円未満 440円、10万円未満
              660円、299999円まで 1,100円
              <br /> 銀行振込手数料
            </dd>

            <dt>支払方法</dt>
            <dd>
              クレジットカード払い
              <br />
              Amazon Pay
              <br />
              <Link to="https://checkout.rakuten.co.jp/">楽天ペイ</Link>
              <br />
              代金引換
              <br />
              銀行振込
              <br />
              オリコWEBクレジット(10回払い無金利)
              <br />
              あと払い (ペイディ)
              <br />
              <Link to="/">支払い方法の詳細はこちら</Link>
              <br />
            </dd>

            <dt>支払期限</dt>
            <dd>
              代金引換：ご注文日時により1週間以内
              <br /> 銀行振込：ご注文日時より金融機関の3営業日以内
              <br />
              <p>
                上記の期限を超える場合、お客様のご都合に応じてその都度ご相談させていただきますので、お気軽にご連絡くださいませ。ただし、当店にご連絡無くキャンセルがございました場合、次回からのご利用をお断りさせていただいております。
              </p>
            </dd>

            <dt>商品の引渡し時期</dt>
            <dd>
              ご注文から3日以内に発送いたします。
              <br />
              銀行振込でお支払いの場合は、ご入金確認後3日以内に発送いたします。
              <br />
            </dd>

            <dt>返品の取扱方法</dt>
            <dd>
              商品のお受け取りから3日以内に当社指定の方法により申し出た上で、別途定める条件を満たした場合に限り、返品をお受けします。初期不良品・品違い・破損の場合は、当店にて返品送料を負担いたします。詳しくはショッピングガイド「
              <Link to="/">ご注文のキャンセル・返品・交換</Link>
              」をご確認ください。
            </dd>

            <dt>販売業者の連絡先</dt>
            <dd>
              TEL： 0761-23-5999
              <br />
              メールでのお問合わせは<Link to="/contact">こちら</Link>から
            </dd>
          </dl>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Particular;
